import React, { SVGProps } from 'react';
import { styled, StyledComponentProps, Theme } from '@mui/material';
import { StyledComponent } from '@emotion/styled';
import { MUIStyledCommonProps } from '@mui/system';

import spriteIcons from '../../assets/svg/sprite.icons.svg';

const StyledIcon: StyledComponent<
  MUIStyledCommonProps<Theme>,
  SVGProps<SVGSVGElement>,
  Record<any, any>
> = styled('svg')`
  display: inline-block;
  stroke: currentColor;
  fill: currentColor;
  stroke-width: 0;
  vertical-align: -0.125em;
  height: 1em;
  width: 1em;
`;

export interface IIcon extends StyledComponentProps<'svg'> {
  iconName: string;
  className?: string;
  style?: Record<string, string | number>;
}

const Icon: React.FC<IIcon> = ({ iconName, className, ...props }) => {
  return (
    <StyledIcon className={`icons ${iconName} ${className}`} {...props}>
      <use xlinkHref={`${spriteIcons}#${iconName}`} />
    </StyledIcon>
  );
};

export default Icon;
